<div class="app-name text-center">
    <a routerLink="/">{{'app.name' | translate}} </a>
    <img src="../assets/images/jabil-logo.png" class="jabil-logo">
</div>
@if (user$ | async; as user) {

<div class="circle" [ngStyle]="{'background-color': user['color']}">
    <span class="initials">{{ user['initials'] }}</span>
</div>

<div class="user-name-wrapper">
    <span class="user-name">{{ user.name | surname }}</span>
    <span class="logout" (click)="onLogoutClick()">{{'app.logout' | translate}}</span>
</div>
}

<div class="nav-wrapper">
    <button mat-button class="nav-btn" [ngClass]="{'active-nav': activeTab === 'personal-info'}"
        routerLink="/personal-info">
        <span class="nav-element">
            <mat-icon>perm_contact_calendar</mat-icon>
            {{ 'app.personalInfo' | translate}}
        </span>
    </button>

    <button mat-button class="nav-btn" [ngClass]="{'active-nav': activeTab === 'vacations'}" routerLink="/vacation">
        <span class="nav-element">
            <mat-icon>beach_access</mat-icon>
            {{ 'app.vacations' | translate}}
        </span>
    </button>

    <button mat-button class="nav-btn" [ngClass]="{'active-nav': activeTab === 'absence'}" routerLink="/absence">
        <span class="nav-element">
            <mat-icon>schedule</mat-icon>
            {{ 'app.absence' | translate}}
        </span>
    </button>

    <button mat-button class="nav-btn" [ngClass]="{'active-nav': activeTab === 'rcp'}" routerLink="/rcp">
        <span class="nav-element">
            <mat-icon>event</mat-icon>
            {{ 'app.workCalendar' | translate}}
        </span>
    </button>

    <button mat-button class="nav-btn" [ngClass]="{'active-nav': activeTab === 'equipment'}" routerLink="/equipment">
        <span class="nav-element">
            <mat-icon>engineering</mat-icon>
            {{ 'app.equipment' | translate}}
        </span>
    </button>

    <button mat-button class="nav-btn" [ngClass]="{'active-nav': activeTab === 'idea'}" routerLink="/idea">
        <span class="nav-element">
            <mat-icon>lightbulb</mat-icon>
            {{ 'app.idea' | translate}}
        </span>
    </button>

    <button mat-button class="nav-btn" [ngClass]="{'active-nav': activeTab === 'loan'}" routerLink="/loan">
        <span class="nav-element">
            <mat-icon>savings</mat-icon>
            {{ 'app.pkzp' | translate}}
        </span>
    </button>

    <button mat-button class="nav-btn" [ngClass]="{'active-nav': activeTab === 'salary'}" routerLink="/salary">
        <span class="nav-element">
            <mat-icon>paid</mat-icon>
            {{ 'app.salary' | translate}}
        </span>
    </button>

    <button mat-button class="nav-btn" [ngClass]="{'active-nav': activeTab === 'taxes'}" routerLink="/taxes">
        <span class="nav-element">
            <mat-icon>request_quote</mat-icon>
            {{ 'app.taxes' | translate}}
        </span>
    </button>

    <button mat-button class="nav-btn" [ngClass]="{'active-nav': activeTab === 'assessment'}" routerLink="/assessment">
        <span class="nav-element">
            <mat-icon>emoji_events</mat-icon>
            {{ 'app.assessment' | translate}}
        </span>
    </button>


    <!-- <button mat-button class="nav-btn" [ngClass]="{'active-nav': activeTab === 'calc'}" routerLink="/calc">
        <span class="nav-element">
            <mat-icon>calculate</mat-icon>
            {{ 'app.calc' | translate}}
        </span>
    </button> -->
</div>