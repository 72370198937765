@if (busy()) {
<mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
@if ((authService.authState$ | async)?.isAuthenticated) {
<!-- left panel  -->
<div class="grid">
  <!-- left panel  -->
  <p-sidebar [(visible)]="showSidebar" [style]="{width:'230px'}" styleClass="left-panel-background" *onMobile="true">
    <app-left-panel></app-left-panel>
  </p-sidebar>
  <!-- for big screens -->
  <div class="left-panel hide-on-small-screen left-panel-background" *onMobile="false">
    <app-left-panel></app-left-panel>
  </div>
  <!-- main -->
  <div class="main-wrapper">
    <div class="top-bar flex mat-elevation-z1">
      <!-- menus for small screens-->
      <button mat-icon-button class="hide-on-big-screen link-flex" (click)="showSidebar = !showSidebar">
        <mat-icon>menu</mat-icon>
      </button>
      @if (areTopNavsAvailable) {
      <button mat-button class="link-flex border-left border-right hide-on-big-screen" [matMenuTriggerFor]="otherMenu">
        {{'app.other' | translate}}
      </button>
      }
      <!-- normal view -->
      @if (areTopNavsAvailable) {
      <button mat-button class="link-flex border-right hide-on-small-screen"
        (click)="openUrlClick('https://jabil.sharepoint.com/:f:/s/ADMINISTRACJAformularze/Ei_-DsdyfsNFmjC6V7M2C00But9aLlRoJK6ia5z1gCjG8A?e=Aco5GV')">
        {{'app.forms' | translate}}<mat-icon>open_in_new</mat-icon>
      </button>
      }
      @if (areTopNavsAvailable) {
      <button mat-button class="link-flex border-right hide-on-small-screen"
        (click)="openUrlClick('https://ekaizen.jblapps.com/')">
        E-Kaizen <mat-icon>open_in_new</mat-icon>
      </button>
      }
      @if (areTopNavsAvailable) {
      <button mat-button class="link-flex border-right hide-on-small-screen" [matMenuTriggerFor]="nominateMenu">
        {{'app.nominate' | translate}}
        @if (nominationCount$|async; as nominationCount) {
        <p-badge [value]="nominationCount.toString()" severity="info"></p-badge>
        }
      </button>
      }
      @if (areTopNavsAvailable) {
      <button mat-button class="link-flex border-right hide-on-small-screen" routerLink="/write-to-supervisor">
        {{'app.writeToSupervisor' | translate}}
      </button>
      }
      @if (areTopNavsAvailable) {
      <button mat-button class="link-flex border-right hide-on-small-screen"
        (click)="openUrlClick('https://jabil.sharepoint.com/:f:/s/Rekrutacja_2023/EvH1e0r822dOp_YSvKbOlBEBOQCz75k3mX_Zp_O1Jc5Skw?e=JLuoIe')">
        {{'app.newJobs' | translate}}<mat-icon>open_in_new</mat-icon>
      </button>
      }
      <div class="end-buttons">
        <button mat-icon-button routerLink="/help">
          <mat-icon>help_outline</mat-icon>
        </button>
        <button mat-icon-button class="link-flex" [matMenuTriggerFor]="moreMenu">
          <mat-icon>manage_accounts</mat-icon>
        </button>
      </div>
    </div>
    <!-- routing -->
    <router-outlet></router-outlet>
  </div>
</div>
<!-- more menu -->
<mat-menu #moreMenu="matMenu" backdropClass="menu">
  <button mat-menu-item [matMenuTriggerFor]="languages">
    <mat-icon>language</mat-icon>
    <span>{{'language' | translate}}</span>
  </button>
  <button mat-menu-item routerLink="/settings">
    <mat-icon>settings</mat-icon>
    <span>{{'newsSettings' | translate}}</span>
  </button>
</mat-menu>

<!-- languages menu -->
<mat-menu #languages="matMenu" class="menu sub-menu">
  <button mat-menu-item class="flag" [disabled]="translate.currentLang ==='pl-PL'" (click)="changeLang('pl-PL')">
    <img src="assets/images/pl.png" alt="image">
    <span>Polski</span>
  </button>
  <button mat-menu-item class="flag" [disabled]="translate.currentLang ==='en-US'" (click)="changeLang('en-US')">
    <img src="assets/images/usa.png" alt="image">
    <span>English</span>
  </button>
</mat-menu>

<!-- nomination menu -->
<mat-menu #nominateMenu="matMenu" class="sub-menu menu">
  @if (isAdmin$|async) {
  <button mat-menu-item routerLink="/settings/category">
    <span>{{'app.configure' | translate}}</span>
  </button>
  }
  @if (isAdmin$|async) {
  <button mat-menu-item routerLink="/settings/report">
    <span>{{'app.report' | translate}}</span>
  </button>
  }

  <button mat-menu-item routerLink="/add-nomination">
    <span>{{'app.nominateMenu' | translate}}</span>
  </button>

  <button mat-menu-item routerLink="/nominations-inbox">
    <span>{{'app.yourNominations' | translate}}</span>
    @if (nominationCount$| async; as nominationCount) {
    <p-badge [value]="nominationCount.toString()" severity="info"></p-badge>
    }
  </button>
</mat-menu>
<!-- standard menu for small screen -->
<mat-menu #otherMenu="matMenu" backdropClass="menu">
  <button mat-menu-item Xclass="link-flex"
    (click)="openUrlClick('https://jabil.sharepoint.com/:f:/s/ADMINISTRACJAformularze/Ei_-DsdyfsNFmjC6V7M2C00But9aLlRoJK6ia5z1gCjG8A?e=Aco5GV')">
    {{'app.forms' | translate}} <mat-icon>open_in_new</mat-icon>
  </button>
  <button mat-menu-item class="link-flex" (click)="openUrlClick('https://ekaizen.jblapps.com/')">
    E-Kaizen <mat-icon>open_in_new</mat-icon>
  </button>
  <button mat-menu-item class="link-flex" [matMenuTriggerFor]="nominateMenu">
    {{'app.nominate' | translate}}
  </button>
  <button mat-menu-item class="link-flex" routerLink="/write-to-supervisor">
    {{'app.writeToSupervisor' | translate}}
  </button>
  <button mat-menu-item class="link-flex"
    (click)="openUrlClick('https://jabil.sharepoint.com/:f:/s/Rekrutacja_2023/EvH1e0r822dOp_YSvKbOlBEBOQCz75k3mX_Zp_O1Jc5Skw?e=JLuoIe')">
    {{'app.newJobs' | translate}} <mat-icon>open_in_new</mat-icon>
  </button>
</mat-menu>
} @else {
<router-outlet></router-outlet>
}
